<template>
   <div class="c-card-group">
      <div class="c-card">
        <div class="c-card-column-3">
          <div class="c-card-item">
            <div class="c-card-title px-3">
              <div class="c-icon-text">
              <div>Active messages</div>
                <IconQuestionMark />
              </div>
            </div>
            <div class="c-card-block">
              <img :src="ActivityGeoMessage" />
              <p class="text-blue">6</p>
            </div>
          </div>
          <div class="c-card-item text-center">
            <div class="c-card-title px-3">Receivers heard</div>
            <div class="c-card-block mx-auto">
              <img :src="ActivityMessageHeard" />
              <p class="text-green">4</p>
            </div>
          </div>
          <div class="c-card-item text-right">
            <div class="c-card-title px-3">...not yet heard</div>
            <div class="c-card-block ml-auto">
              <img :src="ActivityMessageNotHeard" />
              <p class="text-navy">53</p>
            </div>
          </div>
        </div>
        <div class="c-arrow-icon">
          <div class="c-icon-text-2">
              <IconArrowDown />
              <div>Today</div>
              </div>
        </div>
      </div>

      <div class="c-card c-card-sm">
        <div class="c-card-item">
          <div class="c-card-column-2">
            <div class="c-card-title">
            <div class="c-icon-text">
              <div>History</div>
                <IconQuestionMark />
              </div>
            </div>
            <div class="c-card-title justify-end flex">
            <div class="c-icon-text-2">
              <IconArrowDown />
              <div>Total to date</div>
              </div>
            </div>
          </div>
          
          <div class="c-card-content">
             <div class="c-content-row">
                <div>Geofence Messages</div>
                <div class="c-value">1,089</div>
             </div>
             <div class="c-content-row">
                <div>Messages heard</div>
                <div class="c-value">893</div>
             </div> 
             <div class="c-content-row">
                <div>Receivers</div>
                <div class="c-value">561</div>
             </div> 
             <div class="c-content-row">
                <div>Communication Efficiency</div>
                <div class="c-value-big">82%</div>
             </div>
          </div>
        </div>
      </div>
   </div>

   <div class="align-center justify-center activity-table">
      <ListTable
        :indeterminate="indeterminate"
        :items="users"
        :selectedItemCount="selectedContacts"
        ref="tableRef"
        :isWhiteIcons="true"
        :headers="contactheader"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        @toggle-row-select="selectMembers"
        :thsearch="false"
        :showCount="true"
        :isReload="true"
        :totalItems="7"
        :totalCountText="'Receivers'"
        :totalCountText1="'Contact'"
        :dynamicSearchHeight="false"
        :isSelectGlobalCheckBox="isSelectAll"
        @select-all="selectAll"
        :page="0"
      > 
        <template v-slot:table_body="{ item }">
          <td>
            <div>{{ item.item.name }}</div>
          </td>
          <td>
            <div class="filter-cell" v-if="item.item.status == 'pending'"><PropertyIcon /> <span>Atlas Hill Condos</span></div>
            <div class="filter-cell" v-if="item.item.status == 'active'"><AreaIcon /> <span>Kootenai</span></div>
          </td>
          <td>
            <div class="fixed-length-text" style="max-width: 242px;">{{ item.item.location }}</div>
          </td>
          <td>
            <div class="fixed-length-text" style="max-width: 234px;">{{ item.item.messages }}</div>
          </td>
          <td>
            <div class="settings-actions">
              <SettingDots/>
            </div>
          </td>
          <td class="last-col">
            <div class="heard-block">
              <div v-if="item.item.isHeard">Heard at {{ item.item.heard }}</div>
              <ActivityRowHeard v-if="item.item.isHeard" />
              <ActivityRowNotHeard v-if="!item.item.isHeard" />
            </div>
          </td>
        </template>
      </ListTable>
    </div>

    
</template>

<script setup>
import IconQuestionMark from '@/components/icons/IconQuestionMark.vue';
import IconArrowDown from '@/components/icons/IconArrowDown.vue';
import ActivityGeoMessage from '@/assets/images/icons/Activity-Geo-Message.svg';
import ActivityMessageHeard from '@/assets/images/icons/Activity-Message-heard.svg';
import ActivityMessageNotHeard from '@/assets/images/icons/Activity-Message-not-heard.svg';
import ListTable from "@/components/common/ListTable.vue";
import ActivityRowHeard from '@/components/icons/ActivityRowHeard';
import ActivityRowNotHeard from '@/components/icons/ActivityRowNotHeard';
import SettingDots from '@/components/icons/message_settings/SettingDots';
import AreaIcon from "@/components/icons/Area";
import PropertyIcon from "@/components/icons/Property.vue";

 

const contactheader= [
      { key: "name", title: "Name",},
      {
        key: "groups",
        title: "5 Groups",
        class: "pr-0",
        isFilter: true,
        filterItems: [
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
          { label: 'Pending', value: 'pending' },
          { label: 'Verified', value: 'verified' },
        ],
      },
      { key: "location", title: "4 Locations"},
      { key: "messages", title: "Messages"},
      { key: "details", title: "Details" },
      {
        key: "status",
        title: "Status",
        class: "pr-0",
        isFilter: true,
        filterItems: [
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
          { label: 'Pending', value: 'pending' },
          { label: 'Verified', value: 'verified' },
        ],
      },
      
    ]

  let users = [{
    name : "Kai Brunner",
    group : "Atlas Hill Condos",
    location: "Hemlock Lane, Sandpoint, Idaho Sandpoint, Idaho",
    messages: "Use North Entrance after 5:00pm Use North Entrance after 5:00pm",
    status: "pending",
    heard: "08:29 am",
    isHeard: true,
  },
  {
    name : "Nik Edmiidz",
    group : "Atlas Hill Condos",
    location: "Hemlock Lane, Sandpoint, Idaho Sandpoint, Idaho",
    messages: "Use North Entrance after 5:00pm",
    status: "active",
    heard: "08:29 am",
    isHeard: true,
  },
  {
    name : "Kishor Kumar",
    group : "Atlas Hill Condos",
    location: "Hemlock Lane, Sandpoint, Idaho Sandpoint, Idaho",
    messages: "Use North Entrance after 5:00pm",
    status: "active",
    heard: "09:40 am",
    isHeard: true,
  },
  {
    name : "Maksym Cherkasov",
    group : "Kootenai",
    location: "Hemlock Lane, Sandpoint, Idaho Sandpoint, Idaho",
    messages: "Use North Entrance after 5:00pm",
    status: "active",
    heard: "08:29 am",
    isHeard: true,
  },
  {
    name : "Vladimir Federov",
    group : "Kootenai",
    location: "Brittany Loop",
    messages: "Use North Entrance after 5:00pm",
    status: "active",
    heard: "08:29 am",
    isHeard: true, 
  },
  {
    name : "Fedor Levikov",
    group : "Kootenai",
    location: "Brittany Loop",
    messages: "Use North Entrance after 5:00pm",
    status: "active",
    heard: "08:29 am",
    isHeard: false, 
  },
  {
    name : "Brandon Hope",
    group : "Kootenai",
    location: "Brittany Loop",
    messages: "Use North Entrance after 5:00pm",
    status: "pending",
    heard: "08:29 am",
    isHeard: false, 
  }]

  let indeterminate  = false;

  function selectMembers(e){

  }

  let isSelectAll = false;
</script>

<style lang="scss" scoped>
 .c-card-group {
    display: grid;
    grid-template-columns: 1fr 440px;
    column-gap: 60px;
 }

 .c-card-column-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
 }

 .c-card-column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
 }

 .content-type-1 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    & .btn-group {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        height: 85px;
    }
  }

  .c-content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #E0E0E0;
    line-height: 20px;
    &:last-child {
      border-bottom: none;
    }
    & .c-value {
      font-size: 24px;
      line-height: 20px;
      color: #344D7A;
    }
    & .c-value-big {
        font-size: 48px;
        color: #344D7A;
        line-height: 1.2;
    }
  }

  

  .switch-group {
    margin-top: 27px;
  }

 .c-card {
    background-color: #fff;
    padding: 33px 30px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    
    & .c-card-title {
        font-size: 16px;
        line-height: 18px;
        color: #000;
        padding-bottom: 9px;
        border-bottom: 1px solid #E0E0E0;
        font-weight: 500;
    }
    &.c-card-sm {
      padding: 28px 30px 0px;
      & .c-card-title { 
        padding-bottom: 7px;
        line-height: 24px;
      }
    }
    & .c-card-item {
      display: flex;
      flex-direction: column;
      flex: 1;
        & .c-card-content {
          display: flex;
          flex-direction: column;
          flex: 1;
        & ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
 }

 .btn-outline {
    display: flex;
    width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #7B8794;
    border-radius: 5px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #000;
 }
 .btn-blue-filled {
    display: flex;
    min-width: 100px;
    height: 36px;
    align-items: center;
    padding: 0 17px;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    line-height: 16.41px;
    cursor: pointer;
    color: #fff;
    background-color: #4066CF;
    text-transform: uppercase;
    font-weight: 500;
    transition: 0.2s all ease;
    &:hover {
      background-color: #6B87D4;
    }
 }

 .status-text {
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    font-weight: 500;
 }

 .activity-table {
    margin-top: 26px;
    margin-bottom: 60px;
    
  }


 .invite-button {
    width: 50px;
    height: 20px;
    background: #7B8794;
    font-size: 13px;
    line-height: 16px;
    color: #F2F2F2;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
 }

 .c-icon-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .c-icon-text-2 {
    display: flex;
    align-items: center;
  }

  
  .c-card-block {
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 18px 20px;
    gap: 23px;
    & p {
      font-size: 74px;
      line-height: 1;
      font-weight: 300;
      &.text-blue {
        color: #4992E6;
      }
      &.text-green {
        color: #25AC1E;
      }
      &.text-navy {
        color: #344D7A;
      }
    }
  } 


  .c-arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .heard-block {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    gap: 17px;
    color: #333333;
    font-weight: 600;
    white-space: nowrap;
    flex-shrink: 0;
    margin-left: auto;
  }
  .settings-actions {
    display: flex;
    justify-content: center;
  }
  .last-col {
    display: flex;
    justify-content: flex-end;
  }
</style>
