<template>
  <div class="hovered-btn">
    <svg class="default"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M8 11L12 15L16 11"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

   
    <svg
       class="hovered"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5V21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21V5Z"
          fill="#E0E0E0"
          stroke="#E0E0E0"
          stroke-width="8"
        />
        <path
          d="M8 11L12 15L16 11"
          stroke="#7B8794"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

  </div>
</template>